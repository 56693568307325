import '@/css/app.pcss';

import Glide from '@glidejs/glide';

// For the on Doc ready: Define a convenience method and use it
let ready = (callback) => {
  if (document.readyState != "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
}

ready(() => {
  /* Do things after DOM has fully loaded */
  console.log('Doc is ready!');
  
  /* ----------------- Blog Teaser Carousel (only on mobile) */
  
  const blogCarousel = document.querySelector('.blog-teaser-panel-carousel');
  
  /* Error handler checks to see if carousel is present */
  
  if (typeof(blogCarousel) != 'undefined' && blogCarousel != null) {
    
    var blogCarouselGlide = new Glide(blogCarousel, {
      type: 'carousel',
      perView: 1,
      gap: 45
    });
  
    blogCarouselGlide.mount();
   
  }
   
  /* ----------------- Press Carousel */
  
  const pressCarousel = document.querySelector('.press-panel-carousel');
  
  /* Error handler checks to see if carousel is present */
  
  if (typeof(pressCarousel) != 'undefined' && pressCarousel != null) {
    
    var pressCarouselGlide = new Glide(pressCarousel, {
      type: 'carousel',
      perView: 8,
      gap: 45,
      autoplay: 1,
      animationDuration: 3000,
      animationTimingFunc: 'linear',
      breakpoints: {
        1024: {
          gap: 35,
          perView: 6
        },
        767: {
          perView: 4
        }
      }
    });
  
    pressCarouselGlide.mount();
   
   }

   const seenIn = document.querySelector('.press-panel-carousel-seenIn');
  
   /* Error handler checks to see if carousel is present */
   
   if (typeof(seenIn) != 'undefined' && seenIn != null) {
     
     var pressCarouselGlideSeenIn = new Glide(seenIn, {
      type: 'carousel',
      perView: 8,
      gap: 45,
      autoplay: 1,
      animationDuration: 3000,
      animationTimingFunc: 'linear',
      breakpoints: {
        1024: {
          gap: 35,
          perView: 6
        },
        767: {
          perView: 4
        }
      }
    });
   
     pressCarouselGlideSeenIn.mount();
    
    }
   
   /* ----------------- Testimonials Carousel */
   
   const testimonialsCarousel = document.querySelector('.testimonials-panel-carousel');
   
   /* Error handler checks to see if carousel is present */
   
   if (typeof(testimonialsCarousel) != 'undefined' && testimonialsCarousel != null) {
     
     var testimonialsCarouselGlide = new Glide(testimonialsCarousel, {
       type: 'carousel',
       perView: 1,
     });
   
     testimonialsCarouselGlide.mount();
    
    }

       
   /* ----------------- Testimonials About ResourcesCarousel */
   
   const testimonialsAboutResourcesCarousel = document.querySelector('.testimonials-about-resources-carousel');
   
   /* Error handler checks to see if carousel is present */
   
   if (typeof(testimonialsAboutResourcesCarousel) != 'undefined' && testimonialsAboutResourcesCarousel != null) {
     
     var testimonialsAboutResourcesCarouselGlide = new Glide(testimonialsAboutResourcesCarousel, {
       type: 'carousel',
       perView: 1,
     });
   
     testimonialsAboutResourcesCarouselGlide.mount();
    
    }

     /* ----------------- Testimonials Work 1  Carousel */
   
     const testimonialsWork1 = document.querySelector('.testimonials-work-1-carousel');
   
     /* Error handler checks to see if carousel is present */
     
     if (typeof(testimonialsWork1) != 'undefined' && testimonialsWork1 != null) {
       
       var testimonialsWork1Glide = new Glide(testimonialsWork1, {
         type: 'carousel',
         perView: 1,
         gap: 0
       });
     
       testimonialsWork1Glide.mount();
      
      }

     /* ----------------- Testimonials Work 2  Carousel */
   
     const testimonialsWork2 = document.querySelector('.testimonials-work-2-carousel');
   
     /* Error handler checks to see if carousel is present */
     
     if (typeof(testimonialsWork2) != 'undefined' && testimonialsWork2 != null) {
       
       var testimonialsWork2Glide = new Glide(testimonialsWork2, {
         type: 'carousel',
         perView: 3,
         gap: 20,
         breakpoints: {
          1024: {
            gap: 0,
            perView: 1
          },
          767: {
            perView: 1,
            gap: 0
          }
        }
       });
     
       testimonialsWork2Glide.mount();
      
      }

         /* ----------------- Testimonials Blog  Carousel */
   
         const testimonialsBlog = document.querySelector('.testimonial-blog-carousel');
   
         /* Error handler checks to see if carousel is present */
         
         if (typeof(testimonialsBlog) != 'undefined' && testimonialsBlog != null) {
           
           var testimonialsBlogGlide = new Glide(testimonialsBlog, {
             type: 'carousel',
             perView: 1,
           });
         
           testimonialsBlogGlide.mount();
          
          }
    
    
    /* ----------------- Category Toggle menu */
    
    const categoryToggles = document.querySelectorAll('.blog-category-nav-toggle'); 
    
    categoryToggles.forEach(categoryToggle => {
      
      categoryToggle.addEventListener('click', (event) => {
        
        // get the parent element so we can toggle its class
        categoryToggle.parentNode.classList.toggle('blog-grid-categories-collapsed-mobile');
        
      });
      
    });
    
    
    
 /* ----------------- FAQ Toggle menu */
    
 const faqToggles = document.querySelectorAll('.faqs-accordian-item-toggle'); 

 faqToggles.forEach(faqToggle => {
   faqToggle.addEventListener('click', (event) => {
     // Add the class directly to the .faqs-accordian-item-toggle element
     faqToggle.classList.toggle('toggle-background-image');
     
     // get the parent element so we can toggle its class
     const faqItem = faqToggle.parentNode;
     faqItem.classList.toggle('faqs-accordian-item-answer-hidden');
   });
 });
 


 
  
}); // END DOC READY




const menu = document.querySelector('.navigation');
const menuMobile = document.querySelector('.mobile-menu');

function updateMenuVisibility() {
  if (window.innerWidth <= 1024) { // You can adjust the width breakpoint as needed
    menuMobile.classList.add('visible');
    menu.classList.remove('hidden');
  } else {
    if (window.scrollY > 0) {
      menuMobile.classList.add('visible');
      menu.classList.add('hidden');
    } else {
      menuMobile.classList.remove('visible');
      menu.classList.remove('hidden');
    }
  }
}

// Call the function initially
updateMenuVisibility();

// Add event listeners for window resize and scroll
window.addEventListener('resize', updateMenuVisibility);
window.addEventListener('scroll', updateMenuVisibility);

// Call the function on page load
updateMenuVisibility();

// Add a resize event listener to update menu visibility when the window size changes
window.addEventListener('resize', updateMenuVisibility);




// Get references to the elements
const wrapper = document.getElementById('hamburger-wrapper')
const mobileNav = document.querySelector('.mobile-menu-links');

// Function to toggle the mobile navigation
function toggleMobileNav() {
  mobileNav.classList.toggle('open');
}

// Add click event listener to the hamburger button
wrapper.addEventListener('click', toggleMobileNav);
wrapper.addEventListener("click", () => {
  wrapper.classList.toggle("open")
})
// Close mobile navigation when clicking outside the menu
document.addEventListener('click', function (event) {
  if (!mobileNav.contains(event.target) && !wrapper.contains(event.target)) {
    mobileNav.classList.remove('open');
  }
});

//Scroll slide

function handleIntersection(entries, observer) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      setTimeout(() => {
        entry.target.classList.add('visible');
      }, 300);
      observer.unobserve(entry.target); // Stop observing once it's visible
    }
  });
}

// Set up the Intersection Observer
const observer = new IntersectionObserver(handleIntersection);





document.addEventListener("scroll", function () {
  var pageTop = window.scrollY || document.documentElement.scrollTop;
  var pageBottom = pageTop + window.innerHeight;
  var tags = document.querySelectorAll(".fadein, .image-fadein");

  tags.forEach(function (tag) {
    var tagTop = tag.getBoundingClientRect().top + pageTop;
    var tagBottom = tagTop + tag.offsetHeight;

    // Check if the element is in the viewport
    if (tagBottom > pageTop && tagTop < pageBottom) {
      tag.classList.add("visible");
    } else {
      tag.classList.remove("visible");
    }
  });
});




document.addEventListener("DOMContentLoaded", function () {
    var changingRowColumn = document.querySelector(".home-changing-second-row");
     
    if (typeof(changingRowColumn) != 'undefined' && changingRowColumn != null) {
     
      var observer = new IntersectionObserver(function (entries) {
         entries.forEach(function (entry) {
             // Check if 60% of the section is on the screen
             if (entry.intersectionRatio >= 0.5) {
                 changingRowColumn.classList.add("visibles"); // Add the 'visible' class to show the element
             } else {
                 changingRowColumn.classList.remove("visibles"); // Remove the 'visible' class to hide the element
             }
         });
      }, { threshold: 0.5 }); // Set the threshold to 60%
      
      // Start observing the target element
      observer.observe(changingRowColumn);
      
    }
});




